<template>
  <b-row class="m-0 p-0">
    <b-col cols="2" class="p-0 d-none d-lg-block">
      <font-awesome-icon :icon="this.icon" size="1x"/>
    </b-col>
    <b-col cols="2" class="p-0 d-none d-lg-block">{{ parent ? `#${parent.stepOrder}.` : '#' }}{{ data.stepOrder }}</b-col>
    <b-col cols="5" class="p-0">
      <Duration v-if="this.data.durationType === 'TIME'" v-bind:duration="this.data.durationValue"/>
      <Distance v-if="this.data.durationType === 'DISTANCE'" v-bind:value="this.data.durationValue"/>
    </b-col>
    <b-col cols="3" class="p-0">
        <span
            v-for="zone in 5"
            :key="zone"
            v-bind:class="[data.zones.includes(zone) ? ['zone', `zone${zone}`, '' ] : ['zone', 'inactive'] ]">{{
            zone
          }} </span>

    </b-col>


  </b-row>

</template>

<script>
import Duration from '@/components/Duration.vue'
import Distance from "@/components/Distance";

export default {
  name: 'WorkoutStep',
  components: {
    Duration,
    Distance,
  },
  props: {
    data: Object,
    parent: Object,
  },
  data() {
    return {
      color: String,
      icon: String,
    }
  },
  created() {
    this.color = '#00ff0020';
    this.icon = 'running';
  },
  computed: {},
  mounted() {
    if (this.data.intensity == 'WARMUP') {
      this.color = '#f5252e80';
      this.icon = 'chevron-up';
    } else if (this.data.intensity == 'ACTIVE') {
      this.color = '#0da9ed80';
      this.icon = 'running';
    } else if (this.data.intensity == 'COOLDOWN') {
      this.color = '#72ea2380';
      this.icon = 'chevron-down';
    }
  }
}
</script>

<style scoped>

.zone {
  font-weight: bold;
}

.inactive {
  color: #dadada;
}

</style>
