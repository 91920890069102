import Vue from 'vue'

import App from './App.vue'
import {initializeApp} from 'firebase/app';
import {getAuth, getIdToken} from 'firebase/auth';


import VueCodemirror from 'vue-codemirror'

import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/yaml/yaml.js'

Vue.use(VueCodemirror)


import axios from 'axios'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faCog,
    faCogs,
    faLink,
    faUserPlus,
    faBan,
    faCopy,
    faArchive,
    faEdit,
    faHeartbeat,
    faTrash,
    faTrashRestore,
    faStopwatch,
    faRunning,
    faClock,
    faRoute,
    faArrowUp,
    faArrowDown,
    faExternalLinkAlt,
    faCheckSquare,
    faSquare,
    faRedo,
    faTimes,
    faTimesCircle,
    faRoad,
    faChevronUp,
    faChevronDown,
    faExchangeAlt,
    faBell,
    faUpload,
    faSyncAlt,
    faSpinner,
    faCaretRight,
    faCalendarAlt,
    faMask,
    faSignOutAlt,
    faSitemap,
    faUsers,
    faInfoCircle,
    faUser,
    faShare,
} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import router from './router'

library.add(
    faCog,
    faCogs,
    faLink,
    faUserPlus,
    faBan,
    faCopy,
    faArchive,
    faEdit,
    faHeartbeat,
    faTrash,
    faTrashRestore,
    faStopwatch,
    faRunning,
    faClock,
    faRoute,
    faArrowUp,
    faArrowDown,
    faExternalLinkAlt,
    faCheckSquare,
    faSquare,
    faRedo,
    faTimes,
    faTimesCircle,
    faRoad,
    faChevronUp,
    faChevronDown,
    faExchangeAlt,
    faBell,
    faUpload,
    faSyncAlt,
    faSpinner,
    faCaretRight,
    faCalendarAlt,
    faMask,
    faSignOutAlt,
    faSitemap,
    faUsers,
    faInfoCircle,
    faUser,
    faShare,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.prototype.$http = axios

axios.interceptors.request.use(async config => {
    // TODO: handle token refresh
    config.headers['X-Firebase-ID-Token'] = await getIdToken(getAuth().currentUser);
    return config
}, (error) => {
    return Promise.reject(error)
})


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueDragDrop from 'vue-drag-drop';
Vue.use(VueDragDrop);

import {VBHoverPlugin} from 'bootstrap-vue'

Vue.use(VBHoverPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/main.css'

let app = '';
initializeApp({
    apiKey: "AIzaSyCX5dMb6An_Z8ja8PzQIz8Bfgg35Ya46O0",
    authDomain: "runners-high-2bb42.firebaseapp.com",
    projectId: "runners-high-2bb42",
    storageBucket: "runners-high-2bb42.appspot.com",
    messagingSenderId: "536588833421",
    appId: "1:536588833421:web:5f86701e43488454b488c4"
});


getAuth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            render: h => h(App)
        }).$mount('#app');
    }
});


