<template>
  <b-container>
    <b-alert v-if="usersWithoutTeams.length > 0" show variant="warning">There are runners with no teams assigned.
      <router-link :to="{ name: 'users-and-teams', query: { filter: 'true' }}">Assign</router-link>
      them at least one team so they will receive the published workouts.
    </b-alert>

    <b-row class="text-left">
      <b-col>
        <h2>Workouts</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="sitemap" size="1x"/>
          {{ globalData.currentOrganization.name }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input
            autofocus
            v-model="filter"
            placeholder="Search Workouts"
            autocomplete="off"
        />
      </b-col>
      <b-col cols="3">
        <b-form-checkbox class="text-right" v-model="showInactive" size="lg" switch @change="onShowInactiveChange()">
          Show inactive
        </b-form-checkbox>
      </b-col>
      <b-col cols="4" align-h="end" class="text-right">
        <b-button variant="success" class="mr-1" @click="onCreateWorkout">Create</b-button>
        <b-button
            :disabled="selectedUuids.length === 0"
            variant="primary"
            class="mr-1"
            @click="onPublish(selectedUuids)">Publish {{ selectedUuids.length }} Workouts
        </b-button>
      </b-col>
    </b-row>
    <br/>

    <b-table
        ref="workoutTable"
        class="text-left"
        hover
        small
        :items="filteredWorkouts"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="sm"
        selectable
        @row-selected="onRowSelected"
        @row-hovered="onRowHovered"
        @row-unhovered="onRowUnHovered"

    >
      <template #head(selected)>
        <b-form-checkbox
            :checked="checkedState"
            :indeterminate="indeterminateState"
            @change="onHeaderCheckbox"
        />
      </template>
      <template #cell(selected)="{ rowSelected, index }">
        <b-form-checkbox
            :checked="rowSelected"
            @change="onRowCheckbox(index, rowSelected)"
        />
      </template>

      <template #cell(name)="data">
        <router-link :to="{ name: 'workout', params: { uuid: data.item.uuid }}">{{ data.item.name }}
        </router-link>
      </template>

      <template #cell(labels)="data">
        <LabelSelector
            :globalData="globalData"
            :readOnly=true
            :selectedLabelUuids="data.item.meta.labelUuids ? data.item.meta.labelUuids : []"
        />
        <b-badge v-if="! data.item.active">Inactive</b-badge>
      </template>
      <template #cell(timestamp)="data">
        {{ timestampFormatter.format(data.item.timestamp) }}
      </template>

      <template #cell(actions)="data">
        <span v-if="data.index === hoveredIndex">
        <font-awesome-icon
            class="mr-2"
            :class="data.item.active ? 'text-secondary' : 'text-primary'"
            :icon="data.item.active ? 'trash' : 'trash-restore'"
            :title="data.item.active ? 'Deactivate' : 'Activate'"
            size="1x"
            @click.stop="onActivateWorkout(data.item)"
        />
          <font-awesome-icon
              class="mr-2 text-success"
              icon="share"
              title="Publish"
              size="1x"
              @click.stop="onPublish([data.item.uuid], data.item.name)"
          />
        </span>
      </template>
    </b-table>
  </b-container>

</template>

<script>

import LabelSelector from "../components/Labels/LabelSelector";

export default {

  name: 'Workouts',
  components: {
    LabelSelector,
  },
  data() {
    return {
      filter: "",
      showInactive: false,
      sortBy: 'timestamp',
      sortDesc: true,
      fields: [
        {key: 'selected', thStyle: {width: "30px"},},
        {key: 'name', sortable: true, label: 'Workout Name'},
        {key: "labels", class: "d-none d-sm-table-cell"},
        {key: 'timestamp', sortable: true, label: 'Modified', class: "d-none d-md-table-cell"},
        {key: 'actions', sortable: false, label: '', thStyle: {width: "60px"},},
      ],
      workouts: [],
      usersWithoutTeams: [],
      timestampFormatter: new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }),
      selectedUuids: [],
      hoveredIndex: null,
    }
  },
  props: {
    globalData: Object
  },
  watch: {
    globalData: {
      handler() {
        this.refresh();
      },
      deep: true
    },
  },
  computed: {
    filteredWorkouts() {
      let self = this;
      if (!this.workouts) {
        return [];
      }
      return this.workouts.filter(function (workout) {
        return (workout.active || self.showInactive) && workout.name.toUpperCase().indexOf(self.filter.toUpperCase()) >= 0;
      });
    },
    filteredWorkoutsCount() {
      return this.filteredWorkouts.length;
    },
    selectedWorkoutsCount() {
      return this.selectedUuids.length;
    },
    checkedState() {
      return this.selectedWorkoutsCount === this.filteredWorkoutsCount && this.selectedWorkoutsCount > 0;
    },
    indeterminateState() {
      return this.selectedWorkoutsCount > 0 && this.selectedWorkoutsCount < this.filteredWorkoutsCount;
    }
  }, created() {
    this.refresh();
  },
  methods: {
    onRowSelected(items) {
      this.selectedUuids = items.map(function (workout) {
        return workout.uuid;
      });
    },
    onShowInactiveChange() {
      this.refresh();
    },
    onRowHovered(item, index) {
      this.hoveredIndex = index;
    },
    onRowUnHovered() {
      this.hoveredIndex = null;
    },
    refresh() {
      this.$http.get('/api/workouts', {params: {'showInactive': this.showInactive}})
          .then(response => {
            this.workouts = response.data
            response.data.forEach(item => {
              item.timestamp = Date.parse(item.lastModified)
            })
          })
          .catch(e => {
            console.warn(e)
          })
      this.$http.get('/api/admin/users-without-teams')
          .then(response => {
            this.usersWithoutTeams = response.data
          })
          .catch(e => {
            console.warn(e)
          })
    },

    onCreateWorkout() {
      this.$http.post(`/api/workouts/create`)
          .then(() => {
            this.refresh();
          })
          .catch(e => {
            console.warn(e)
          })
    },
    onHeaderCheckbox() {
      if (this.selectedUuids.length < this.filteredWorkouts.length) {
        this.$refs.workoutTable.selectAllRows();
      } else {
        this.$refs.workoutTable.clearSelected();
      }
    },
    onRowCheckbox(index, rowSelected) {
      console.error(index, rowSelected);
      rowSelected ? this.$refs.workoutTable.unselectRow(index) : this.$refs.workoutTable.selectRow(index);
    },
    onActivateWorkout(workout) {
      let workoutUuid = workout.uuid;
      let active = !workout.active;

      this.$http.post(`/api/workouts/${workoutUuid}/activate`, {active: active})
          .then(() => {
            this.refresh();
          })
          .catch(e => {
            console.warn(e)
          })
    },
    onPublish(workout_uuids, workoutName) {
      this.$http.post(`/api/workouts/publish`,
          {workout_uuids: workout_uuids})
          .then(() => {
            let body = workout_uuids.length > 1 ? `${workout_uuids.length} Workouts` : workoutName;

            this.$bvToast.toast(`Published to ${this.globalData.currentOrganization.name} Runners`, {
              title: body,
              toaster: 'b-toaster-bottom-center',
              variant: 'success',
              solid: true,
              autoHideDelay: 1200,
              noCloseButton: true,
              appendToast: true,
            })

          })
          .catch((e) => {
            console.warn(e)
          })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
