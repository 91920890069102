<template>
  <b-card>
    <b-card-title class="text-left">Active Workouts</b-card-title>
    <b-card-sub-title class="text-left">Synced to Your App</b-card-sub-title>

    <b-table
        striped
        small
        hover
        :items="data"
        :fields="fields"
        class="text-left mt-2"
        thead-tr-class="d-none"
    >
      <template #cell(meta)="data">
        <Duration :duration="data.item.meta.duration"/>
      </template>
    </b-table>
  </b-card>
</template>

<script>

import Duration from "@/components/Duration.vue";

export default {

  name: 'UserWorkouts',
  components: {Duration},
  props: {
    uuid: String,
    compact: Boolean,
    showLink: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      data: [],
      fields: [
        {key: "name", class: "text-truncate"},
        {key: "meta", class: "text-truncate"},
      ],
    }
  },
  created() {
    this.$http.get(`/api/active-workouts`)
        .then(response => {
          this.data = response.data;
        })
        .catch(() => {
          // console.warn(e)
        });
  },
}
</script>
