<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="primary" sticky style="z-index: 10000;" v-if="user != null">
      <b-navbar-brand :to="{ name: 'home'}">
        <font-awesome-icon icon="running" size="1x" id="icon"/>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- Group -->
          <b-nav-item-dropdown v-if="currentOrganization">
            <template #button-content>
              <font-awesome-icon icon="sitemap" size="1x"/>
              {{ currentOrganization.name }}
            </template>
            <b-dropdown-item
                v-for="organization in organizations"
                :key="organization.group.uuid" @click="setCurrentOrganization(organization.group.uuid)"
            >
              {{ organization.group.name }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- Team -->
          <b-nav-item-dropdown v-if="currentOrganization">
            <template #button-content>
              <font-awesome-icon icon="users" size="1x"/>
              {{ currentTeam ? currentTeam.name : '' }}
            </template>
            <b-dropdown-item
                v-for="team in teams"
                :key="team.group.uuid" @click="setCurrentTeam(team.group.uuid)"
            >
              {{ team.group.name }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <!--            <b-nav-item :to="{ name: 'groups'}">Groups</b-nav-item>-->
          <b-nav-item :to="{ name: 'runners'}">Runners</b-nav-item>
          <b-nav-item :to="{ name: 'workouts'}">Workouts</b-nav-item>
          <b-nav-item :to="{ name: 'plans'}" v-if="isSuperAdmin">Plans</b-nav-item>
          <b-nav-item :to="{ name: 'my-activities'}">My Activities</b-nav-item>
          <!--            <b-nav-item :to="{ name: 'plan'}">Plan</b-nav-item>-->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">


          <b-nav-item-dropdown right v-if="isAdmin">
            <template #button-content>
              Manage
            </template>
            <b-dropdown-item :to="{ name: 'users-and-teams'}">Users and Teams</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'my-organizations'}">My Organizations</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'labels'}">Labels</b-dropdown-item>
            <b-dropdown-divider v-if="isSuperAdmin"/>
            <b-dropdown-item :to="{ name: 'organizations'}" v-if="isSuperAdmin">Organizations</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'users-and-roles'}" v-if="isSuperAdmin">All Users</b-dropdown-item>
            <b-dropdown-divider v-if="isSuperAdmin"/>
            <b-dropdown-item :to="{ name: 'jobs'}" v-if="isSuperAdmin">
              <font-awesome-icon icon="cogs" size="1x"/>
              Jobs
            </b-dropdown-item>
            <b-dropdown-divider v-if="isSuperAdmin"/>
            <b-dropdown-item :to="{ name: 'system'}" v-if="isSuperAdmin">
              <font-awesome-icon icon="cog" size="1x"/>
              System
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown id="notifications-dropdown-header" no-caret right>
            <b-tooltip target="notifications-dropdown-header">{{ jobs.length }} Notifications</b-tooltip>
            <template slot="button-content">
              <font-awesome-icon icon="bell" :class="jobs.length > 0 ? 'text-warning' : 'text-dark'" size="1x"/>
            </template>
            <b-dropdown-text>
              <div class="job-dropdown-menu">Notifications</div>
            </b-dropdown-text>
            <b-dropdown-divider/>
            <b-dropdown-text v-for="job in jobs" :key="job.id" class="job-dropdown-item mt-1">
              <template>
                <b-row>
                  <b-col>
                    <!-- API Validation -->
                    <template v-if="job.type === 'api-validate'">
                      <font-awesome-icon icon="upload" class="mr-2"/>
                      Validating Garmin API for {{ job.data.count }} {{ job.data.count > 1 ? 'users' : 'user' }}
                    </template>

                    <!-- Publish Workout -->
                    <template v-if="job.type === 'publish-workout'">
                      <font-awesome-icon icon="upload" class="mr-2"/>
                      Publishing
                      <router-link
                          :to="{ name: 'workout', params: { uuid: job.data.workout.uuid }}">{{ job.data.workout.name }}
                      </router-link>
                      to {{ job.data.count }} {{ job.data.count > 1 ? 'users' : 'user' }}
                    </template>

                    <!-- Schedule Workout -->
                    <template v-if="job.type === 'schedule-workout'">
                      <font-awesome-icon icon="calendar-alt" class="mr-2"/>
                      Publishing
                      <router-link
                          :to="{ name: 'plan', params: { uuid: job.data.plan.uuid }}">{{ job.data.plan.name }}
                      </router-link>
                      to {{ job.data.count }} {{ job.data.count > 1 ? 'users' : 'user' }}
                    </template>

                    <!-- Workout Sync -->
                    <template v-if="job.type === 'workouts-sync'">
                      <font-awesome-icon icon="sync-alt" class="mr-2"/>
                      Syncing {{ job.data.count }} {{ job.data.count > 1 ? 'users' : 'user' }}
                    </template>

                    <!-- Generic Job -->
                    <template v-else>
                      <font-awesome-icon icon="spinner" class="mr-2"/>
                      Working ({{ job.type }})...
                    </template>
                  </b-col>

                  <!-- Delete (ack) button-->
                  <b-col cols="1">
                    <a href="#">
                      <font-awesome-icon
                          icon="times-circle"
                          @click="ackJob(job.type, job.uuid)"/>
                    </a>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-progress :value="job.progress * 100" max="100" height="3px" class="mt-1"/>
                  </b-col>
                </b-row>
              </template>

            </b-dropdown-text>
          </b-nav-item-dropdown>

          <b-avatar v-bind:src="avatar" class="d-none d-lg-block mt-1" size="2em"></b-avatar>
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->

            <template #button-content>
              <em>{{ displayName }}</em>
            </template>
            <b-dropdown-item :to="{ name: 'profile'}">Profile</b-dropdown-item>
            <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-alert variant="danger" class="text-center p-2" :show="impersonationActive">Impersonating as {{ activeUser.name }} ({{ activeUser.username }})
      <b-button @click="endImpersonation()" size="sm" class="ml-1">
        <font-awesome-icon icon="sign-out-alt" size="1x"/>
      </b-button>
    </b-alert>
    <router-view class="router-view" :globalData="globalData" @active-user-changed="loadActiveUser"/>
  </div>
</template>


<script>
import {getAuth} from 'firebase/auth';
import GlobalDataMixin from "@/components/GlobalDataMixin";

export default {
  name: 'App',
  mixins: [GlobalDataMixin],
  data() {
    return {
      // The user who is authenticated in the system
      user: Object,
      // The active/impersonated user
      activeUser: {
        type: Object,
        default() {
          return null;
        }
      },
      // The organizations the user belongs to
      organizations: Array,
      teams: Array,
      // Selected organization
      currentOrganization: {
        type: Object,
        default() {
          return null;
        }
      },
      // Selected team
      currentTeam: {
        type: Object,
        default() {
          return null;
        }
      },
      notifications: Number,
      jobs: Array,
      labels: {
        type: Array,
        default() {
          return ['label']
        }
      }
    }
  },
  computed: {
    globalData: function () {
      return {
        activeUser: this.activeUser,
        zones: [1, 2, 3, 4, 5],
        labels: this.labels,
        organizations: this.organizations,
        currentOrganization: this.currentOrganization,
        currentTeam: this.currentTeam
      }
    },
    displayName: function () {
      return this.user ? this.user.displayName : null;
    },
    avatar: function () {
      return this.user ? this.user.photoURL : null;
    },
    impersonationActive() {
      return this.user && this.activeUser.username && this.user.email !== this.activeUser.username;
    }
  },
  methods: {
    loadLabels() {
      this.$http.get('/api/labels')
          .then(response => {
            this.labels = response.data;
          })
          .catch((e) => {
            console.error(e)
          });
    },
    loadActiveUser() {
      this.$http.get(`/api/profile`)
          .then(response => {
            console.debug(`Active user: ${response.data.username}`)
            this.activeUser = response.data
          })
          .catch(e => {
            console.log(e);
          })
      this.getOrganizations();
    },
    getOrganizations() {
      this.$http.get(`/api/groups/current`)
          .then(response => {
            this.parseOrganizationResponse(response.data)

          })
          .catch(e => {
            console.log(e);
          })
    },
    parseOrganizationResponse(responseData) {
      this.organizations = responseData['organizations']
      console.debug(`Organizations: ${this.organizations}`)

      this.currentOrganization = responseData['current_organization']
      console.debug(`Current Organization: ${this.currentOrganization}`)

      this.teams = responseData['teams']
      console.debug(`Teams: ${this.teams}`)

      this.currentTeam = responseData['current_team']
      console.debug(`Current Team: ${this.currentTeam}`)
    },
    setCurrentOrganization(groupUuid) {
      this.$http.post(`/api/groups/current_organization/${groupUuid}`)
          .then(response => {
            this.parseOrganizationResponse(response.data);
            this.loadLabels()
          }).catch(function (e) {
        console.log(e)
      });
    },
    setCurrentTeam(groupUuid) {
      this.$http.post(`/api/groups/current_team/${groupUuid}`)
          .then(response => {
            this.parseOrganizationResponse(response.data);
          }).catch(function (e) {
        console.log(e)
      });
    },
    ackJob(jobType, jobUuid) {
      console.debug(`Ack Job ${jobUuid} (${jobType})`);
      this.$http.delete(`/api/jobs/${jobUuid}`)
          .then(() => {

          }).catch(function (e) {
        console.log(e)
      });
    },
    endImpersonation() {
      this.$http.post(`/api/sa/end_impersonation`)
          .then(() => {
            this.loadActiveUser();
          }).catch(function (e) {
        console.log(e)
      });
    },
    logout() {
      this.$http.get(`/api/logout`).then(() => {
        getAuth().signOut().then(() => {
          this.currentOrganization = null;
          this.$router.push({name: 'login'})
        })
      })
    }
  },
  mounted() {
    this.jobs = [];

    let self = this;
    getAuth().onAuthStateChanged((user) => {
      this.user = user;
      if (this.user != null) {
        self.getOrganizations();
      }
    });

    if (this.user != null) {

      // We authenticate first to make sure that we have a fresh server-side session
      this.$http.get(`/api/auth`)
          .then(() => {
            self.loadActiveUser();
            self.loadLabels();

            // TODO: refresh when expired
            getAuth().currentUser.getIdToken(false).then(function (idToken) {
              // Connect to WS
              console.debug("Starting connection to WebSocket Server")
              let loc = window.location;
              let protocol = loc.protocol === "https:" ? "wss:" : "ws:";
              let ws_uri = `${protocol}//${loc.hostname}/api/ws?id_token=${idToken}`;
              self.connection = new WebSocket(ws_uri)

              self.connection.onmessage = function (message) {
                self.jobs = JSON.parse(message.data);
              }

              self.connection.onopen = function (event) {
                console.debug(event)
                console.debug("WebSocket Opened")
              }

              self.connection.onclose = function (event) {
                // TODO: retry connect
                console.debug(event)
                console.debug("WebSocket Closed")
              }

            })
                .catch(e => {
                  console.warn(e)
                })

          }).catch(function (e) {
        console.log(e)
      });
    }
  }
  ,
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.router-view {
  margin-top: 30px;
  text-align: left;
}

#notifications-dropdown-header {
  margin-right: 10px;
}

.job-dropdown-item {
  width: 100%;
}

.job-dropdown-menu {
  width: 400px;
  font-weight: 900;
}
</style>
