<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-row class="text-left">
      <b-col>
        <h2>Runners</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="users" size="1x"/>
          {{ globalData.currentTeam.name }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input
            autofocus
            v-model="filter"
            placeholder="Search Runners"
            autocomplete="off"/>
      </b-col>
    </b-row>
    <br/>
    <b-table
        hover
        small
        :items="workouts"
        :fields="fields"
        :filter="filter"
        @row-hovered="onRowHovered"
        @row-unhovered="onRowUnHovered"
    >
      <template #head(thresholdPace)="data">
        <span class="d-none d-sm-block">{{ data.label }}</span>
        <span class="d-block d-sm-none">Pace</span>
      </template>

      <template #cell(name)="data">
        <router-link :to="{ name: 'users-and-teams', query: { q: data.item.username }}">{{
            data.item.name
          }}
        </router-link>

      </template>
      <template #cell(labels)="data">

        <LabelSelector
            :globalData="globalData"
            :readOnly="! isAdmin"
            :selectedLabelUuids="data.item.data.labelUuids"
            v-on:selected-labels-changes="onRunnerLabelsUpdate(data.item)"
        />
        <b-badge class="ml-2" v-if="! data.item.enabled">Inactive</b-badge>
      </template>
      <template #cell(thresholdPace)="data">
        <Duration v-bind:duration="data.item.data.thresholdPace.minutes * 60 +data.item.data.thresholdPace.seconds "/>
      </template>
      <template #cell(activities)="data">
        <router-link :to="{ name: 'user-activities', params: { uuid: data.item.uuid }}">
          View
        </router-link>
      </template>
      <template #cell(actions)="data">
        <font-awesome-icon
            v-if="data.index === hoveredIndex"
            class="row-button mr-2"
            :class="data.item.enabled ? 'text-secondary' : 'text-primary'"
            :icon="data.item.enabled ? 'trash' : 'trash-restore'"
            :title="data.item.enabled ? 'Deactivate' : 'Activate'"
            size="1x"
            @click.stop="onToggleUserState(data.item)"
        />
      </template>
    </b-table>
  </b-container>
</template>

<script>

import Duration from "../components/Duration";
import LabelSelector from "../components/Labels/LabelSelector";
import ForbiddenBanner from "../components/ForbiddenBanner";
import ForbiddenContentMixin from "../components/ForbiddenContentMixin";
import GlobalDataMixin from "@/components/GlobalDataMixin.vue";

export default {
  name: 'Runners',
  mixins: [GlobalDataMixin, ForbiddenContentMixin],
  components: {
    Duration,
    ForbiddenBanner,
    LabelSelector,
  },
  data() {
    return {
      workouts: [],
      fields: [
        {key: "name", label: "Runner Name", class: "text-truncate"},
        {key: "labels", class: "d-none d-sm-table-cell", thStyle: {width: "200px"}},
        {key: "username", label: "Email", class: "d-none d-md-table-cell"},
        {key: "thresholdPace"},
        {key: "activities"},
        {key: 'actions', sortable: false, label: '', thStyle: {width: "60px"}},
      ],
      filter: '',
      hoveredIndex: null,
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$http.get(`/api/users`)
          .then(response => {
            this.forbidden = false;
            this.workouts = response.data
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
    onRowHovered(item, index) {
      this.hoveredIndex = index;
    },
    onRowUnHovered() {
      this.hoveredIndex = null;
    },
    onToggleUserState: function (user) {
      let userUuid = user.uuid;
      user.enabled = !user.enabled;
      console.log(`${userUuid} -> ${user.enabled}`);

      this.$http.post(`/api/users/${userUuid}/enable`, {enabled: user.enabled})
          .then(() => {
          })
          .catch(e => {
            console.warn(e)
          })
    },
    onRunnerLabelsUpdate: function (user) {
      let userUuid = user.uuid;
      console.log(`${userUuid} -> ${user.data.labelUuids}`);
      this.$http.post(`/api/users/${userUuid}`, {labelUuids: user.data.labelUuids})
          .then(() => {
          })
          .catch(e => {
            console.warn(e)
          })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row-button {
  cursor: pointer;
}
</style>
