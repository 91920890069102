<template>
  <b-card
      class="workout-card text-left m-1 mt-2 mb-2"
      border-variant="secondary"
      :body-class="compact ? 'pl-1 pr-1 pt-1 pb-0' : ''"
  >
    <b-card-title
        title-tag="h6"
        :class="compact ? 'mt-1 mb-0' : ''"
    >

      <b-row>
        <b-col
            class="text-truncate"
            cols="8">
          <b-link v-if="showLink" :to="{ name: 'workout', params: { uuid: uuid }}">{{ name }}</b-link>
          <span v-else>{{ name }}</span>
        </b-col>
        <b-col class="text-right" cols="4">
          <Duration v-if="compact && duration" v-bind:text-class="'small'" v-bind:duration="duration"/>
          <Distance v-if="compact && distance" v-bind:text-class="'small'" v-bind:value="distance"/>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-body
        class="p-1 "
        :class="compact ? 'ml-0 mr-0' : ''"
    >
      <WorkoutContents :steps="w.steps" v-if="! compact"></WorkoutContents>
      <b-row class="pt-1  " v-if="compact">
        <b-col>
          <WorkoutSvgContent :steps="w.steps" :duration="duration" :distance="distance"/>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>

</template>

<script>

import WorkoutContents from "@/components/Workouts/WorkoutContents";
import WorkoutSvgContent from "@/components/Workouts/WorkoutSvgContent";
import Duration from "@/components/Duration";
import Distance from "@/components/Distance";

export default {

  name: 'WorkoutCard',
  components: {
    WorkoutContents,
    WorkoutSvgContent,
    Distance,
    Duration,
  },
  data() {
    return {
      loadedWorkout: null
    }
  },
  props: {
    compact: Boolean,
    showLink: {
      type: Boolean,
      default: false
    },
    uuid: null,
    workout: {
      type: Object,
      default: () => ({
        name: '',
        steps: [],
        meta: {
          duration: 0,
          distance: 0,
        }
      }),
    },
  },
  created() {

    let vm = this;
    if (this.uuid != null ) {
      this.$http.get(`/api/workouts/${this.uuid}`)
          .then(response => {
            vm.loadedWorkout = response.data.workout;
            vm.loadedWorkout.meta = response.data.meta;
            vm.loadedWorkout.name = response.data.name;
          })
          .catch((e) => {
            console.warn(e);
          });
    }
  },
  computed: {
    w() {
      return this.loadedWorkout != null ? this.loadedWorkout : this.workout;
    },
    name() {
      return this.w.name;
    },
    duration() {
      return this.w.meta.duration;
    },
    distance() {
      return this.w.meta.distance;
    },
  },
}
</script>

<style scoped>
</style>
