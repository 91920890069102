<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-row class="text-left">
      <b-col>
        <h2>System</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="cog" size="1x"/>
          Configuration
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search System" autofocus autocomplete="off"/>
      </b-col>
    </b-row>
    <br/>
    <b-table
        hover
        small
        :items="data"
        :fields="fields"
        :filter="filter"
    />
  </b-container>
</template>

<script>

import ForbiddenBanner from "../../components/ForbiddenBanner";
import ForbiddenContentMixin from "../../components/ForbiddenContentMixin";

export default {
  name: 'Jobs',
  mixins: [ForbiddenContentMixin],
  components: {
    ForbiddenBanner,
  },
  data() {
    return {
      data: [],
      fields: [
        {key: "key", sortable: false, class: "text-truncate",  thStyle: {width: "400px"}},
        {key: "value", sortable: false, class: "text-truncate"},
      ],
      filter: '',
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$http.get(`/api/sa/configuration`)
          .then(response => {
            this.forbidden = false;
            this.data = response.data
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
  }
}
</script>

<style scoped>
</style>
