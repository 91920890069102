<template>
  <b-container>
    <b-row class="text-left">
      <b-col>
        <h2>{{ plan ? plan.name : "" }}</h2>
        <h5 class="text-secondary">{{ globalData.currentTeam.name }}</h5>
      </b-col>
    </b-row>

    <b-table hover :items="plan ? plan.slots : []" :fields="fields" class="text-left">
      <template #cell(name)="data">
        <b-form-input
            class="text-primary"
            style="width: 10rem"
            :placeholder="data.item.name"
            plaintext/>
      </template>

      <template #cell(dayOfWeek)="data">
        <DayOfWeekSelector
            style="width: 10rem"
            :dayOfWeek="data.item.dayOfWeek"
            @change="onDayOfWeekChange($event, data.item.uuid)"
        />
      </template>
    </b-table>

  </b-container>
</template>

<script>

import DayOfWeekSelector from "../components/Plans/DayOfWeekSelector";

export default {
  name: 'Plan',
  components: {
    DayOfWeekSelector,
  },
  props: {
    uuid: String,
    globalData: Object
  },
  watch: {
    globalData: {
      handler(newVal, oldVal) {
        const oldTeamUuid = oldVal ? oldVal.currentTeam.uuid : 'oldTeamUuid';
        const newTeamUuid = newVal ? newVal.currentTeam.uuid : 'newTeamUuid';
        if (oldTeamUuid !== newTeamUuid) {
          this.refresh();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      plan: null,
      fields: [
        {key: "name"},
        {key: "dayOfWeek"},
      ],
      days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh: function () {
      this.$http.get(`/api/plans/${this.uuid}`)
          .then(response => {
            this.plan = response.data;
          })
          .catch(e => {
            if (e.response.status === 403) {
              // User is not allowed to see this plan. Send him to the Plans page
              this.$router.push({name: 'plans'})
            } else {
              console.error(e);
            }
          })
    },
    onDayOfWeekChange: function (value, slotUuid) {
      console.debug(`change slot ${slotUuid} to ${this.days[value]}`);
      this.$http.post(`/api/plans/schedule_slot`, {
        slotUuid: slotUuid,
        groupUuid: this.globalData.currentTeam.uuid,
        dayOfWeek: value
      })
          .then(() => {
            // Update the local model
            this.plan.slots.forEach(slot => {
              if (slot.uuid === slotUuid) {
                slot.dayOfWeek = value ? parseInt(value) : null;
              }
            })
            // this.refresh();
          })
          .catch(e => {
            console.warn(e)
          })
    }
  },
}
</script>
<style scoped>
.container {
  margin-top: 30px;
}
</style>
