<script>

import GlobalDataMixin from "@/components/GlobalDataMixin.vue";

export default {
  name: 'ForbiddenContentMixin',
  mixins: [GlobalDataMixin],
  data() {
    return {
      forbidden: false
    }
  },
  props: {
    globalData: Object
  },
  computed: {
    activeUser() {
      return this.globalData.activeUser;
    }
  },
  watch: {
    activeUser: {
      handler() {
        this.refresh();
      },
    },
  },
  methods: {
    HandleResponse(e) {
      if (e.response.status === 403) { // Forbidden
        this.forbidden = true;
      } else {
        console.warn(e)
      }
    }
  }
}
</script>
