<template>
  <b-card>
    <b-card-title class="text-left">Send to My Garmin</b-card-title>
    <b-card-sub-title v-if="user" class="text-left">
      Using <strong>Garmin Connect™&nbsp;</strong>
      <a href="https://connect.garmin.com/" target="_blank">
        <font-awesome-icon icon="external-link-alt"/>
      </a>
    </b-card-sub-title>
    <b-card-body>
      <template v-if="connected">
        <b-card-text>
          <b-button v-b-hover="hoverHandler" @click="disconnect" :variant="isHovered ? 'danger' : 'success'" size="sm">{{
              isHovered ? 'Disconnect' : 'Connected'
            }}
          </b-button>
        </b-card-text>
      </template>

      <template v-else>
        <b-card-text>
          <b-button @click="connect" variant="primary" size="sm">Connect</b-button>
        </b-card-text>
      </template>
    </b-card-body>
    <div class="alert alert-warning mb-0" role="alert" v-if="! connected">
      You are not connected
    </div>
  </b-card>
</template>

<script>
import {getAuth} from 'firebase/auth';

export default {
  name: 'GarminConnector',
  props: {
    globalData: Object,
  },
  mounted() {
    this.refresh();
  },
  data() {
    return {
      user: Object,
      connected: false,
      isHovered: false,
    }
  },
  watch: {
    globalData: {
      handler() {
        this.refresh();
      },
      deep: true
    },
  },
  methods: {
    refresh() {
      this.$http.get(`/api/garmin/validate`)
          .then(response => {
            this.connected = response.data.valid
          })
          .catch(e => {
            this.connected = false;
            console.log(e)
          })
    },
    hoverHandler(isHovered) {
      this.isHovered = isHovered;
    },
    connect() {
      // // TODO: refresh when expired
      getAuth().currentUser.getIdToken(false).then(function (idToken) {
        window.open(`/api/submit?id_token=${idToken}`,
            'newwindow',
            'width=500,height=1000');
      }).catch(function (e) {
        console.warn(e)
      });
    },
    disconnect() {
      this.$http.post(`/api/garmin/invalidate`)
          .then(() => {
            this.connected = false;
          })
          .catch(e => {
            console.warn(e)
          })
    }
  },
}
</script>

<style scoped>
</style>
