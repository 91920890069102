<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-row class="text-left">
        <b-col>
          <h2>{{ workoutName }}&#8205;</h2>
          <h5 class="text-secondary">{{ globalData.currentOrganization.name }}</h5>
        </b-col>
      </b-row>
      <b-row class="pt-4 pb-2 p-0">
        <b-col cols="3"> Workout Labels:
          <LabelSelector
              :globalData="globalData"
              :readOnly="false"
              :selectedLabelUuids="labelUuids"
              v-on:selected-labels-changes="onEdit"
          />
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button class="mr-1" size="sm" :disabled="hasErrors" variant="outline-success" @click="publish">
            Publish
            <font-awesome-icon icon="share"/>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class="mt-2 mb-2" border-variant="secondary">
            <h5>Workout Code:</h5>
            <b-card-body class="pb-0">
              <CodeMirror
                  id="codemirror"
                  ref="codemirror"
                  v-model="workout_yaml"
                  :options="cmOptions"
                  @input="onEdit"
              />
              <b-alert
                  show
                  v-if="hasErrors"
                  variant="warning"
                  class="text-center mt-2 mb-0 p-2"
              >Please fix the workout errors before publishing
              </b-alert>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col>
          <WorkoutCard :workout="workout" :compact="true" class="d-none d-xl-block"/>
          <WorkoutCard :workout="workout"/>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import WorkoutCard from "../components/Workouts/WorkoutCard";
import LabelSelector from "../components/Labels/LabelSelector";
import ForbiddenContentMixin from "@/components/ForbiddenContentMixin.vue";
import ForbiddenBanner from "@/components/ForbiddenBanner.vue";
import {codemirror as CodeMirror} from "vue-codemirror/src";

export default {

  name: 'Workout',
  mixins: [ForbiddenContentMixin],
  components: {
    WorkoutCard,
    LabelSelector,
    ForbiddenBanner,
    CodeMirror
  },
  props: {
    uuid: String,
    globalData: Object
  },
  data() {
    return {
      showOverlay: false,
      workout_yaml: '',
      workout: {
        name: '',
        steps: [],
        meta: {
          duration: 0,
          distance: 0,
        }
      },
      labelUuids: [],
      workoutName: "",
      hasErrors: false,
      // duration: Number,
      // steps: [],
      cmOptions: {
        tabSize: 2,
        mode: 'text/x-yaml',
        theme: 'default',
        lineNumbers: true,
        line: true,
      }
    }
  },
  created() {
  },
  mounted() {
    this.refresh();
  },
  computed: {
    codemirror() {
      return this.$refs.codemirror.codemirror;
    }
  },

  methods: {
    refresh() {
      this.$http.get(`/api/workouts/${this.uuid}`)
          .then(response => {
            this.forbidden = false;
            this.workout_yaml = response.data.yaml
            this.workout = response.data.workout;
            this.workout.meta = response.data.meta;
            this.labelUuids = response.data.meta.labelUuids ? response.data.meta.labelUuids : [];
            this.workoutName = response.data.name;
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
    onEdit: function () {
      console.debug(this.workout_yaml);

      this.$http.post(`/api/workouts/${this.uuid}/save`, {
            workout_yaml: this.workout_yaml,
            meta: {
              labelUuids: this.labelUuids,
            },
          }
      )
          .then(response => {
            this.hasErrors = false;
            console.debug(response.data.workout)
            this.workoutName = response.data.name;
            this.workout = response.data.workout;
            this.workout.meta = response.data.meta;

            const start = {line: 0, ch: 0};
            const end = {line: this.codemirror.lastLine(), ch: this.codemirror.getLine(this.codemirror.lastLine()).length};
            const markers = this.codemirror.findMarks(start, end);
            markers.forEach(marker => marker.clear());

          })
          .catch(e => {
            this.hasErrors = true;
            const markers = e.response ? e.response.data.markers : null
            if (markers) {
              markers.forEach(marker => {
                const start = {line: marker.line, ch: 0};
                const end = {line: marker.line, ch: this.codemirror.getLine(marker.line).length};

                this.codemirror.markText(start, end, {
                  css: "text-decoration1: wavy red underline; background-color: #f002",
                });
              })
            }
            this.HandleResponse(e);
          })
    },
    publish: function () {
      this.showOverlay = true;
      this.$http.post(`/api/workouts/publish`,
          {workout_uuids: [this.uuid]})
          .then(() => {
            this.showOverlay = false;
          })
          .catch((e) => {
            console.warn(e)
            this.showOverlay = false;
          })
    },
  }
}
</script>
<style scoped>
</style>
<style>

.CodeMirror {
  border: 1px solid #ddd;
  font-size: 13px;
  height: 65vh;
}
</style>
