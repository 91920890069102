<template>
  <b-container>
    <b-alert v-if="usersWithoutTeams.length > 0" show variant="warning">There are runners with no teams assigned.
      <router-link :to="{ name: 'users-and-teams', query: { filter: 'true' }}">Assign</router-link>
      them at least one team so they will receive the published workouts.
    </b-alert>
    <b-row class="text-left">
      <b-col>
        <h2>Plans</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="sitemap" size="1x"/>
          {{ globalData.currentOrganization.name }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input
            autofocus
            v-model="filter"
            placeholder="Search Plans"
            autocomplete="off"/>
      </b-col>
    </b-row>
    <br/>

    <b-table
        hover
        small
        :items="plans"
        :fields="fields"
        :filter="filter"
        class="text-left"
        @row-hovered="onRowHovered"
        @row-unhovered="onRowUnHovered"
    >

      <template #cell(name)="data">
        <router-link :to="{ name: 'assign-workouts', params: { uuid: data.item.uuid }}">{{
            data.item.name
          }}
        </router-link>
        <b-badge class="ml-2" v-if="! data.item.active">Inactive</b-badge>
      </template>

      <template #cell(timestamp)="data">
        {{ timestampFormatter.format(data.item.timestamp) }}
      </template>

      <template #cell(slots)="data">
        {{ data.item.slots.length }}
      </template>

      <template #cell(actions)="data">
        <font-awesome-icon
            v-if="data.index === hoveredIndex"
            class="row-button mr-2"
            :class="data.item.active ? 'text-secondary' : 'text-primary'"
            :icon="data.item.active ? 'trash' : 'trash-restore'"
            :title="data.item.active ? 'Deactivate' : 'Activate'"
            size="1x"
            @click.stop="onTogglePlanState(data.item)"
        />
        <b-link :to="{ name: 'plan', params: { uuid: data.item.uuid }}">
          <font-awesome-icon
              v-if="data.index === hoveredIndex"
              class=" row-button mr-2"
              icon="calendar-alt"
              title="Edit Schedule"
              size="1x"
              @click.stop="onToggleUserState(data.item)"
          />
        </b-link>

      </template>
    </b-table>
  </b-container>
</template>

<script>

export default {

  name: 'PlanList',

  props: {
    globalData: Object
  },
  watch: {
    globalData: {
      handler() {
        this.refresh();
      },
      deep: true
    },
  },
  data() {
    return {
      filter: "",
      plans: [],
      fields: [
        {key: "name", label: "Plan Name", class: "text-truncate", thStyle: {width: "300px"}},
        {key: 'timestamp', sortable: true, label: 'Modified', class: "d-none d-md-table-cell"},
        {key: "slots"},
        {key: 'actions', sortable: false, label: '', thStyle: {width: "60px"}},
      ],
      timestampFormatter: new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }),
      usersWithoutTeams: [],
      hoveredIndex: null,
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    onRowHovered(item, index) {
      this.hoveredIndex = index;
    },
    onRowUnHovered() {
      this.hoveredIndex = null;
    },
    refresh() {
      this.$http.get(`/api/plans`)
          .then(response => {
            this.plans = response.data
            response.data.forEach(item => {
              item.timestamp = Date.parse(item.lastModified)
            })
          })
          .catch(e => {
            console.error(e)
          })
      this.$http.get('/api/admin/users-without-teams')
          .then(response => {
            this.usersWithoutTeams = response.data
          })
          .catch(e => {
            console.warn(e)
          })
    },
    onTogglePlanState(plan) {
      this.$http.post(`/api/plans/${plan.uuid}/activate`, {active: ! plan.active})
          .then(() => {
            plan.active = ! plan.active;
          })
          .catch(e => {
            console.warn(e)
          })
    },
  },
}
</script>
<style scoped>
.row-button {
  cursor: pointer;
}
</style>
