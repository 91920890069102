<template>
  <b-card>
    <b-card-title class="text-left">Send to My COROS</b-card-title>
    <b-card-sub-title v-if="user" class="text-left">
      Using <strong>COROS Training Hub&nbsp;</strong>
      <a href="https://t.coros.com/" target="_blank">
        <font-awesome-icon icon="external-link-alt"/>
      </a>
    </b-card-sub-title>
    <b-card-body>
      <template v-if="connected">
        <b-card-text>
          <b-button
              v-b-hover="hoverHandler"
              size="sm"
              :variant="isHovered ? 'danger' : 'success'"
              @click="disconnect"
          >{{
              isHovered ? 'Disconnect' : 'Connected'
            }}
          </b-button>
        </b-card-text>
      </template>

      <template v-else>
        <b-card-text>
          <b-button @click="connect" variant="primary" size="sm">Connect</b-button>
        </b-card-text>
      </template>
    </b-card-body>
    <div class="alert alert-warning mb-0" role="alert" v-if="! connected">
      You are not connected
    </div>
  </b-card>
</template>

<script>
import {getAuth} from 'firebase/auth';

export default {
  name: 'CorosConnector',
  props: {
    globalData: Object,
  },
  mounted() {
    this.refresh();
  },
  data() {
    return {
      user: Object,
      config: Object,
      connected: false,
      isHovered: false,
    }
  },
  watch: {
  },
  methods: {
    refresh() {
      this.$http.get(`/api/coros/config`)
          .then(response => {
            this.config = response.data;
            this.connected = response.data.connected;
          })
          .catch(e => {
            this.connected = false;
            console.log(e)
          })
    },
    hoverHandler(isHovered) {
      this.isHovered = isHovered;
    },
    connect() {
      // // TODO: refresh when expired

      let self = this;
      getAuth().currentUser.getIdToken(false).then(function (idToken) {
        let callback = encodeURIComponent(`${self.config.callbackUrl}?id_token=${idToken}`);
        let url = `${self.config.baseUrl}/oauth2/authorize?`;
        url += `client_id=${self.config.clientId}&redirect_uri=${callback}&state=${self.config.state}&response_type=code`
        window.open(url,
            'newwindow',
            'width=400,height=800');
      }).catch(function (e) {
        console.warn(e)
      });
    },
    disconnect() {
      this.$http.get(`/api/coros/invalidate`)
          .then(() => {
            this.connected = false;
          })
          .catch(e => {
            console.warn(e)
          })
    }
  },
}
</script>

<style scoped>
</style>
