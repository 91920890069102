<template>
  <b-card>
    <b-card-title class="text-left">Groups</b-card-title>
    <b-card-sub-title class="text-left">{{ noneSelected ? 'None' : selectedLabelUuids.length }} selected</b-card-sub-title>
    <b-card-body>
      <b-row class="no-select" v-for="label in globalData.labels" :key="label.id">
        <font-awesome-icon
            class="m-1 mr-3"
            :icon="getLabelIcon(label)"
            :color="getLabelColor(label)"
            @click="onLabelChange(label, ! selectedLabelUuids.includes(label.uuid))"
        />
        {{ label.text }}
      </b-row>
    </b-card-body>
    <div class="alert alert-warning mb-0" role="alert" v-if="noneSelected">
      No groups are selected
    </div>
  </b-card>
</template>

<script>

import LabelMixin from "./LabelMixin";

export default {
  name: 'LabelList',
  mixins: [LabelMixin],
}
</script>
